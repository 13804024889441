import $axios from "axios";

window.$clearAxios = $axios;

const authToken = document.querySelector("meta[name=\"auth-token\"]").getAttribute("content");
const headers = {};
if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
}
window.$axios = $axios.create({
    baseURL: "/api",
    headers
});

window.$axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response) {
        return Promise.reject(error.response);
    }
    return Promise.reject(error);
});
